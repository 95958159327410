@import "../../assets/base.scss";

.verify {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  font-size: 40px;
  color: $light-black;
}

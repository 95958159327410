@import "../../assets/base.scss";

.textDesc {
  display: flex;
  margin: 40px 0;
  height: 345px;

  @include mobile {
    flex-direction: column;
  }

  .mid {
    flex: 1;
    background: $darker-blue;
    margin-left: 10px;
    margin-right: 0;
    height: 345px;

    @include mobile {
      margin: 0;
      width: 90vw;
    }

    &.right {
      margin-left: 0;
      margin-right: 10px;
      @include mobile {
        margin: 0;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 50px 20px;
    }

    .title {
      font-size: 38px;
      line-height: 120.6%;
      margin: 20px;
      color: white;
      @include mobile {
        font-size: 20px;
      }
    }

    .desc {
      font-size: 20px;
      line-height: 116%;
      margin: 20px;
      color: white;
      max-width: 80%;
      @include mobile {
        font-size: 18px;
      }
    }
  }

  .side {
    width: 345px;
    height: 100%;
    overflow: hidden;
    background: $lighter-gray;
    @include mobile {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@import "../../assets/base.scss";

.home {
  min-height: 100vh;
  width: 100vw;

  .overall {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.5);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
      width: 100vw;
    }
    .card {
      background: white;
      border-radius: 4px;
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;

      @include mobile {
        padding: 20px;
        width: 98%;
      }

      video {
        width: 80%;
        outline: none;
        border: 2px solid $blue;
        border-radius: 10px;
        @include mobile {
          width: 95%;
        }
      }

      .linkbtn {
        font-size: 20px;
        line-height: 25px;
        color: $blue;
        background: none;
        border: none;
        margin: 10px;
        cursor: pointer;
        outline: none;
        &:hover {
          color: $orange;
        }
      }
    }
  }
  .main {
    padding-top: 70px;
    background: linear-gradient(119.51deg, $light-blue 0%, #fff8ff 48.54%);
    height: 93vh;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
      height: 80vh;
      padding-top: 0;
      background: white;
    }
    .first {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      .side1 {
        width: 389px;
        margin-right: 170px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mobile {
          width: 90vw;
          margin: 0;
        }

        .title {
          font-size: 40px;
          line-height: 120%;
          color: $dark-gray;
          @include mobile {
            font-size: 30px;
          }
        }

        .desc {
          font-size: 20px;
          line-height: 22px;
          color: $gray;
          @include mobile {
            font-size: 18px;
          }
        }

        .buttons {
          a {
            border: 2px solid $darker-blue;
            box-sizing: border-box;
            border-radius: 4px;
            text-decoration: none;
            color: $darker-blue;
            padding: 10px;
            background: white;
            margin: 10px 20px 10px 0;
            line-height: 22px;
            font-size: 14px;

            &.dark {
              background: $darker-blue;
              color: white;
            }
          }
        }
      }

      .side2 {
        position: relative;
        width: 405px;
        height: 505px;
        @include mobile {
          z-index: -1;
          position: absolute;
          width: 100vw;
          opacity: 0.2;
          top: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
        }

        .img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;

          img {
            height: 100%;
          }
        }

        .shape1 {
          position: absolute;
          width: 405px;
          height: 505px;
          left: 20px;
          top: 40px;
          box-sizing: border-box;
          z-index: -1;
          border: 5px solid $darker-blue;
          background: $darker-blue;
          clip-path: polygon(0% 0%, 0% 99%, 2% 99%, 2% 3%, 98% 2%, 98% 77%, 0 98.5%, 0 100%, 100% 78%, 100% 0%);
        }
      }
    }
  }

  .twoPart {
    padding: 20px 7%;

    .title {
      font-size: 32px;
      line-height: 120.6%;
      color: $dark-gray;
      font-weight: bold;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        border: 1.5px solid $dark-gray;
        width: 25.5px;
        left: 0;
        top: -15px;
      }
    }
  }

  .highlight {
    padding: 20px 5%;

    .title {
      font-size: 32px;
      line-height: 120.6%;
      color: $dark-gray;
      font-weight: bold;
    }
  }

  .noBg {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  .blueBtn {
    font-size: 18px;
    line-height: 120%;
    color: white;
    background: $darker-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 13px 18px;
  }
}

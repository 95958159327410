@import "../../assets/base.scss";

.signup {
  min-height: 100vh;
  width: 100vw;

  .main {
    background: white;
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;

    .left {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      overflow: hidden;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        background: rgba(black, 0.3);
        width: 100%;
        height: 100%;
      }

      & > div {
        width: 490px;
        z-index: 2;
      }

      .text {
        font-size: 40px;
        line-height: 120%;
        font-weight: bolder;
        margin: 15px 0;
      }

      .text2 {
        font-size: 20px;
        line-height: 22px;
        margin: 15px 0;
      }

      .text3 {
        font-size: 16px;
        line-height: 22px;
        margin: 20px 0;

        a {
          color: $darker-blue;
          text-decoration: none;
        }
      }
    }

    .right {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .formGroup {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 470px;

        .head {
          font-size: 20px;
          color: $gray;
          line-height: 22px;
          margin: 20px 5px;

          a {
            color: $blue;
            text-decoration: none;
          }
        }

        .headDash {
          font-size: 20px;
          color: $gray;
          line-height: 22px;
          margin: 5px;
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;

          p {
            background: white;
            padding: 0 10px;
            z-index: 99;
          }

          hr {
            position: absolute;
            top: 20px;
            width: 100%;
          }
        }

        .social {
          width: 470px;
          display: grid;
          grid-template-columns: 48% 48%;
          grid-gap: 4%;

          .googleBtn {
            background: #fff;
            border: 1px solid $google-red;
            border-radius: 4px;
            padding: 19px 0;
            font-size: 20px;
            line-height: 22px;
            cursor: pointer;
            color: $google-red;

            &:focus {
              outline-color: $google-red;
            }

            i {
              font-weight: bolder;
              margin-right: 10px;
            }
          }

          .faceBtn {
            background: #fff;
            border: 1px solid $face-blue;
            border-radius: 4px;
            padding: 19px 0;
            font-size: 20px;
            line-height: 22px;
            cursor: pointer;
            color: $face-blue;

            i {
              font-weight: bolder;
              margin-right: 10px;
            }

            &:focus {
              outline-color: $face-blue;
            }
          }
        }

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          input {
            background: white;
            border: 1px solid $dark-white;
            border-radius: 4px;
            padding: 19px 5%;
            margin: 13px 0;
            font-size: 16px;
            line-height: 22px;
            color: $dark-gray;
            width: 90%;
            position: relative;

            &:placeholder {
              font-size: 16px;
              line-height: 22px;
              color: $placeholder;
              margin-left: 50px;
            }

            &:focus {
              outline-color: $blue;
            }

            &[type="submit"] {
              padding: 19px 50px;
              width: 100%;
              background: $darker-blue;
              color: white;
              cursor: pointer;

              &:hover {
                background: lighten($darker-blue, 10);
              }
            }
          }

          .forgot {
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
            color: $darker-blue;
            font-weight: bolder;
            margin: 5px 13px;
          }
        }
      }
    }
  }

  .noBg {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  .blueBtn {
    font-size: 18px;
    line-height: 120%;
    color: white;
    background: $darker-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 13px 18px;
  }
}

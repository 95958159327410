@import "../../assets/base.scss";

.tabs {
  @include mobile {
    width: 90vw;
  }
  .tabItems {
    display: flex;
    border-bottom: 1px solid $blush-white;
    margin: 30px 0;
    @include mobile {
      overflow: auto;
      width: 100%;
    }

    .tab {
      color: $light-black;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
      cursor: pointer;
      padding: 12px 0;
      margin-right: 40px;
      position: relative;

      @include mobile {
        min-width: 20%;
        width: 100%;
        font-size: 14px;
        padding: 12px 5px;
        margin: 5px;
        // white-space: nowrap;
        box-sizing: border-box;
        display: block;
      }
      &:after {
        content: "";
        border-bottom: 2px solid #f8634b;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 100%;
        transition-property: width;
        transition-duration: 0.1s;
        transition-timing-function: ease-in-out;
      }

      &:hover {
        color: darken($light-black, 20);
      }

      &.active {
        color: $blackish;

        &:after {
          width: 100%;
        }
      }
    }
  }

  .tabView {
    overflow: auto;
    @include mobile {
      width: 100vw;
    }
  }
}

@import "../../../assets/base.scss";

.save {
  padding: 5px 5px 50px 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @include mobile {
    padding: 90px 0;
    min-height: 100vh;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      color: $blue;
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.group {
  flex: 1;
  display: grid;
  grid-template-columns: 30% auto;
  grid-gap: 20px;
  @include mobile {
    grid-template-columns: 100%;
  }
  .card {
    background: white;
    padding: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    height: 40vh;

    .top {
      font-size: 20px;
      line-height: 100%;
      color: $blackish;
      margin: 10px auto;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    .sub {
      font-size: 14px;
      line-height: 18px;
      color: $light-black;
      margin: 5px auto;
    }

    form {
      // width: 90%;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      label {
        font-size: 14px;
        line-height: 18px;
        color: $light-black;
        text-align: left;
      }

      input {
        background: $input-blue;
        mix-blend-mode: normal;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 14px 10px;
        width: 100%;
        border: none;
        margin: 10px auto;
        font-size: 15px;
        line-height: 18px;
        color: $new-black;
        &[type="submit"] {
          background: $darker-blue;
          color: white;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background: lighten($darker-blue, 10);
          }
        }
      }
    }
  }

  .table {
    flex: 1;
    background: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    box-sizing: border-box;
    @include mobile {
      width: 95vw;
      overflow: auto;
    }
  }
}

@import "../../../../assets/base.scss";

.plan {
  display: grid;
  grid-template-columns: 80%;
  grid-gap: 2%;
  align-items: flex-start;

  @include mobile {
    grid-template-columns: 95%;
    margin: 0 auto;
    min-height: 75vh;
    grid-gap: 0;
  }

  .main {
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    min-height: 100vh;
    .top {
      display: flex;
      padding: 40px;
      align-items: center;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        color: #565f62;
        line-height: 21px;

        @include mobile {
        }
      }
      .btns {
        display: flex;

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            font-size: 14px;
            line-height: 16px;
            color: white;
            background: $darker-blue;
            border-radius: 2px;
            padding: 7px 20px;
            border: none;
            outline: none;
            cursor: pointer;
            margin: 0 10px;
            @include mobile {
              margin: 0;
            }
            &:hover {
              background: lighten($darker-blue, 10);
            }
          }
        }
      }
      @include mobile {
        padding: 20px;
        align-items: flex-start;
        &.break {
          flex-direction: column;

          .btn {
            margin: 10px 10px 0 0;
          }
        }
      }
    }
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 40px;
    border-top: 0.5px solid $blush-white;

    @include mobile {
      padding: 24px 20px;
      flex-direction: column;
    }

    .head {
      font-size: 16px;
      line-height: 20px;
      color: $light-black;
    }

    .item {
      font-size: 16px;
      line-height: 18px;
      color: $blackish;
      @include mobile {
        margin: 10px auto;
      }
    }

    .title {
      font-size: 20px;
      line-height: 25px;
      color: $darker-blue;
    }

    .btn {
      button {
        font-size: 14px;
        line-height: 16px;
        color: white;
        background: $darker-blue;
        border-radius: 2px;
        padding: 7px 20px;
        border: none;
        outline: none;

        &:hover {
          background: lighten($darker-blue, 10);
        }
      }
    }
  }

  .form {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .select,
    .input {
      margin: 20px;
      width: 95%;
      display: flex;
      flex-direction: column;
      label {
        color: $light-black;
        font-size: 14px;
        margin: 5px;
      }
      @include mobile {
        margin: 10px 5px;
      }
    }

    .input {
      // width: 9%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      box-sizing: border-box;
      input {
        padding: 18px 15px;
        border-radius: 4px;
        border: none;
        background: $card-input-bg;
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: $light-black;
        outline: none;
        width: 100%;
        box-sizing: border-box;
        &:focus {
          border: 1px solid $blue;
        }
        &::placeholder {
          color: $placeholder;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
        }
      }
    }
  }
}

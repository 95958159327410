@import "../../../assets/base.scss";

.save {
  padding: 5px 5px 10px 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @include mobile {
    padding: 90px 0;
    min-height: 100vh;
    padding-bottom: 10px;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    border-bottom: 1px solid $blush-white;
    flex-direction: column;

    .title {
      color: $blue;
      font-size: 32px;
      line-height: 40px;
      margin: 6px 0;
    }

    .sub {
      font-size: 14px;
      line-height: 18px;
      color: $darker-white;
      margin-bottom: 20px;
    }
  }

  .new {
    margin-top: 30px;
    padding-bottom: 80px;
    border-bottom: 1px solid $blush-white;

    &:last-child {
      border: none;
      padding-bottom: 0;
    }
    @include mobile {
      padding-bottom: 30px;
    }

    .title {
      font-size: 16px;
      line-height: 20px;
      color: $light-black;
    }

    .cards {
      margin: 20px 0;
      display: flex;
      overflow: auto;
      width: 80vw;

      &::-webkit-scrollbar {
        display: none;
      }
      @include mobile {
        width: 100vw;
      }

      .card {
        min-width: 350px;
        overflow: hidden;
        border-radius: 5px;
        max-width: 350px;
        background: white;
        margin: 0 10px;

        @include mobile {
          min-width: 250px;
          max-width: 250px;
        }

        .header {
          display: flex;
          margin: 10px;
          align-items: center;
          @include mobile {
            flex-direction: column;
            align-items: flex-start;
          }

          .title {
            font-size: 19px;
            line-height: 24px;
            color: $fair-black;
            flex: 1;
          }
          .time {
            font-size: 12px;
            line-height: 15px;
            color: $blue;
          }
        }
        .desc {
          margin: 20px 10px;
          font-size: 14px;
          line-height: 18px;
          color: $light-black;
        }
      }
    }
  }
}

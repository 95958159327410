@import "../../assets/base.scss";

.footer {
  /* max-height: 580px;*/
  background: $darker-blue;
  padding: 90px;
  @include mobile {
    padding: 20px;
  }

  .suscribe {
    .form {
      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      label {
        font-size: 24px;
        line-height: 22px;
        color: white;
        @include mobile {
          font-size: 12px;
        }
      }

      div {
        margin: 25px 0;
        @include mobile {
          margin: 10px 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
        }
        input {
          width: 300px;
          background: white;
          border-radius: 4px;
          border: none;
          font-size: 18px;
          line-height: 22px;
          border-radius: 4px;
          padding: 17px;
          outline-color: transparent;
          @include mobile {
            width: 90%;
          }
          &::placeholder {
            font-size: 18px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.4);
          }

          &:focus {
            outline-color: $darker-blue;
          }
        }

        .btn {
          width: 135px;
          padding: 17px;
          margin: 0 10px;
          background: $deep-blue;
          border-radius: 4px;
          color: white;
          font-size: 18px;
          line-height: 22px;
          cursor: pointer;
          @include mobile {
            font-size: 12px;
            padding: 10px;
            margin: 10px 0;
          }
        }
      }
    }
  }

  .nav {
    margin-top: 120px;
    display: flex;

    @include mobile {
      margin-top: 20px;
      flex-direction: column;
    }

    .linkers {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      @include mobile {
        grid-gap: 20px;
      }
      .linker {
        color: white;

        .title {
          font-size: 20px;
          line-height: 22px;
          opacity: 0.9;
          margin-bottom: 10px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            border: 1.5px solid $light-red;
            width: 40px;
            left: 0;
            bottom: -5px;
            border-radius: 5px;
          }
        }

        .links {
          display: flex;
          flex-direction: column;

          a {
            font-size: 18px;
            line-height: 22px;
            opacity: 0.7;
            color: white;
            text-decoration: none;
            margin: 7px 0;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .social {
      color: white;

      @include mobile {
        margin: 30px 0 0 0;
      }

      .title {
        font-size: 22px;
        line-height: 22px;
        opacity: 0.9;
        margin-bottom: 10px;
      }

      .links {
        display: flex;
        margin: 20px 0 40px 0;

        a {
          font-size: 28px;
          line-height: 22px;
          color: white;
          text-decoration: none;
          margin-right: 24px;
          font-weight: bold;
        }
      }

      .foot {
        font-size: 20px;
        line-height: 100%;
        opacity: 0.9;
        margin-bottom: 10px;
      }
    }
  }
}

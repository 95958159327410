@import "../../assets/base.scss";

.table {
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  overflow: hidden;

  @include mobile {
    width: 150%;
  }

  .title {
    height: 50px;
    font-size: 20px;
    color: $light-black;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  table {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
    table-layout: fixed;
    display: inline-table;

    tr {
      border-bottom: 1px solid $blush-white;

      &:last-child {
        border: none;
      }
    }

    td {
      padding: 18px;
      font-size: 16px;
      line-height: 21px;
      margin: 10px;
      color: $new-black;

      .underBadge {
        background: rgba($dark-green, 0.2);
        border-radius: 8.5px;
        padding: 4px 6.5px;
        font-size: 10px;
        line-height: 13px;
        display: inline-block;
        color: $dark-green;
        text-transform: uppercase;

        &.red {
          background: rgba($dark-red, 0.2);
          color: $dark-red;
        }
      }

      &.text {
        font-size: 16px;
        line-height: 21px;
        margin: 10px;
        color: $new-black;
      }
      &.smallText {
        font-size: 12px;
        line-height: 21px;
        margin: 10px;
        color: $new-black;
      }
      &.cash {
        span {
          margin-right: 5px;
        }
      }
      &.boldText {
        font-size: 16px;
        line-height: 21px;
        margin: 10px;
        color: $new-black;
        font-weight: bold;
      }
      &.badge {
        div {
          background: rgba($dark-green, 0.2);
          border-radius: 8.5px;
          padding: 4px 6.5px;
          font-size: 10px;
          line-height: 13px;
          display: inline-block;
          color: $dark-green;
          text-transform: uppercase;

          &.red {
            background: rgba($dark-red, 0.2);
            color: $dark-red;
          }
        }
      }
    }

    th {
      background-color: rgba($pink, 0.3);
      padding: 13px 18px;
      color: $light-gray;
      font-size: 12px;
      mix-blend-mode: normal;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 16px;
    }

    tfoot {
      td {
        padding: 20px;
        font-size: 16px;
        line-height: 19px;
        color: $darker-blue;
        &:last-child {
          text-align: right;
          div {
            display: inline;
          }
          button {
            font-size: 16px;
            line-height: 19px;
            color: $darker-blue;
            background: none;
            border: none;
            cursor: pointer;
            margin: 0 20px;
            outline: none;
            display: inline-block;
            opacity: 0.2;
            cursor: no-drop;
            &.show {
              opacity: 1;
              cursor: pointer;
            }
            .next {
              position: relative;
              width: 13px;
              height: 10.5px;
              margin: 0 10px;
              &:before {
                content: "";
                background: $darker-blue;
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
                position: absolute;
                left: 0;
                top: 0;
                height: 10.5px;
                mix-blend-mode: multiply;
                width: 12px;
              }

              &:after {
                content: "";
                background: $darker-blue;
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
                position: absolute;
                left: 8;
                top: 0;
                height: 10.5px;
                width: 12px;
                mix-blend-mode: multiply;
              }
            }

            .prev {
              position: relative;
              width: 13px;
              height: 10.5px;
              margin: 0 10px;
              &:before {
                content: "";
                background: $darker-blue;
                clip-path: polygon(100% 0, 100% 100%, 0% 50%);
                position: absolute;
                left: 0;
                top: 0;
                height: 10.5px;
                mix-blend-mode: multiply;
                width: 12px;
              }

              &:after {
                content: "";
                background: $darker-blue;
                clip-path: polygon(100% 0, 100% 100%, 0% 50%);
                position: absolute;
                left: 8;
                top: 0;
                height: 10.5px;
                width: 12px;
                mix-blend-mode: multiply;
              }
            }
          }
        }
      }
    }
  }
}

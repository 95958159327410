@import '../../assets/base.scss';

.card {
    width: 250px;
    height: 135px;
    border-radius: 4px;
    background: #0f5798;
    box-shadow: 0 14px 14px rgba(0, 0, 0, .12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px;
    box-sizing: border-box;
    position: relative;

    &:hover {
        transform: scale(1.1);
    }

    &:before {
        content: '';
        background: $blash;
        mix-blend-mode: multiply;
        border-radius: 0 4px;
        width: 57px;
        height: 58px;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    &:after {
        content: '';
        background: $blash;
        mix-blend-mode: multiply;
        border-radius: 4px;
        width: 57px;
        height: 58px;
        position: absolute;
        left: 23px;
        bottom: 10px;
    }

    .name {
        color: white;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 35px;
        position: relative;
        z-index: 2;

        &:before {
            content: '';
            width: 15.9px;
            height: 15.9px;
            background: $mastercard-orange;
            position: absolute;
            right: 0;
            opacity: .8;
            border-radius: 50%;
            z-index: 1;
        }

        &:after {
            content: '';
            width: 15.9px;
            height: 15.9px;
            background: $mastercard-red;
            position: absolute;
            right: 10px;
            border-radius: 50%;
            opacity: .8;
            z-index: 1;
        }
    }

    .digitsGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .digits {
            color: white;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: space-between;
            letter-spacing: 2px;
        }

        .trash {
            background: none;
            border: none;
            cursor: pointer;
            margin-left: 30px;
            outline: none;

            &:hover {
                background: none;
                animation: dance .5s ease-in-out;
            }
        }
    }
}

@keyframes dance {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

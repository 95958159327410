@import "../../assets/base.scss";

.dashboard {
  background: $dark-ash;
  min-height: 81vh;
  padding: 5%;
  display: flex;
  @include mobile {
    padding: 2%;
  }
  .dashView {
    padding: 20px;
    flex: 1;

    @include mobile {
      padding: 0;
    }
  }
}

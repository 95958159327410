@import "../../assets/base.scss";

.blog {
  min-height: 100vh;
  width: 100vw;

  .main {
    .back {
      height: 600px;
      background-size: cover;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 5%;
      position: relative;
      transition-duration: 1s;
      @include mobile {
        height: 100vh;
      }
      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: rgba(#000, 0.3);
        top: 0;
        left: 0;
      }
      & > div {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .title {
        font-size: 40px;
        line-height: 120%;
        color: white;
        margin: 40px 0;
        @include mobile {
          font-size: 20px;
        }
      }
      .date {
        font-size: 20px;
        line-height: 22px;
        color: white;
        margin-bottom: 50px;
      }
      .down {
        i {
          color: white;
          font-weight: bolder;
        }
      }
    }
  }

  .article {
    display: flex;
    flex-direction: column;
    padding: 7%;
    font-size: 20px;
    line-height: 40px;
    color: $dark-gray;
    sub {
      font-size: 20px;
      line-height: 40px;
      color: $dark-gray;
      font-weight: bold;
      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }
    }
    p {
      text-align: justify;
      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }
      &:nth-of-type(2n) {
        position: relative;
        &:after {
          content: "";
          background: url("../../assets/plus.svg");
          width: 150px;
          height: 150px;
          position: absolute;
          left: -7%;
          top: 15%;
          @include mobile {
            height: 100px;
            width: 100px;
          }
        }
      }
    }
    .images {
      margin: 40px 20px;
      display: grid;
      width: 100%;
      grid-template-columns: 45% 45%;
      grid-gap: 20px;
      justify-self: center;
      .img {
        max-height: 502px;
        position: relative;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        .shape {
          position: absolute;
          width: 90%;
          height: 95%;
          z-index: -1;
          top: 10%;
          left: -3%;
          border: 5px solid $darker-blue;
          background: $darker-blue;
          clip-path: polygon(0% 0%, 0 76%, 1% 77%, 1% 2%, 99% 3%, 99% 98%, 0 76%, 0 78%, 100% 100%, 100% 0%);
        }
      }
    }
  }
  .recommend {
    padding: 20px 7%;
    position: relative;
    height: 450px;
    overflow: hidden;
    @include mobile {
      height: 200px;
    }
    .title {
      font-size: 40px;
      line-height: 40px;
      color: $dark-gray;
      @include mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &:after {
      content: "";
      background: url("../../assets/plus.svg");
      width: 150px;
      height: 150px;
      position: absolute;
      right: 0;
      top: 0;
      @include mobile {
        height: 100px;
        width: 100px;
      }
    }

    .list {
      display: flex;
      overflow: auto;
      width: 100%;
      position: absolute;
      left: 0;
      top: 100px;
      z-index: 8;

      @include mobile {
        top: 60px;
      }
      &::-webkit-scrollbar {
        display: none;
      }

      div {
        min-width: 467px;
        height: 350px;
        background: $gray;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 3px;
        padding: 5px;
        text-align: center;
        @include mobile {
          height: 150px;
          min-width: 200px;
          max-width: 300px;
          font-size: 12px;
        }
      }
    }
  }

  .noBg {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  .blueBtn {
    font-size: 18px;
    line-height: 22px;
    color: white;
    background: $darker-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 13px 18px;

    &:hover {
      background: lighten($darker-blue, 10);
    }
  }
}

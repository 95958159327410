@import "../../../assets/base.scss";

.vault {
  padding: 0 30px;
  @include mobile {
    padding: 0;
    width: 10vw;
    min-height: 100vh;
  }
  .title {
    font-size: 32px;
    line-height: 40px;
    color: $darker-blue;
    font-weight: bold;
    margin-bottom: 50px;
  }
  .none {
    font-size: 18px;
    color: $light-black;
    text-align: center;
  }
  .topPart {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    @include mobile {
      display: none;
    }

    & > div {
      display: flex;
    }

    button {
      background: $dark-ash;
      border: 1px solid $blue;
      border-radius: 4px;
      padding: 12px 15px;
      font-size: 14px;
      line-height: 21px;
      color: $gray;
      margin: 5px;
      cursor: pointer;
      &:hover {
        background: darken($dark-ash, 5);
      }
      .icon {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: $darker-blue;
        margin: 0 15px;
        position: relative;

        &:before {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background: $darker-blue;
          position: absolute;
          left: 5px;
          top: -5px;
        }

        &:after {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background: $darker-blue;
          position: absolute;
          left: 10px;
        }
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    align-items: center;
    grid-gap: 25px;

    @include mobile {
      width: 90vw;
      grid-template-columns: 90vw;
    }

    button {
      background: $dark-ash;
      mix-blend-mode: normal;
      border: 1px solid #2f75f7;
      border-radius: 2px;
      font-size: 14px;
      line-height: 16px;
      color: $darker-blue;
      cursor: pointer;
      padding: 15px 22px;
      max-width: 140px;
      margin: 0;

      &:hover {
        background: darken($dark-ash, 5);
      }
    }
  }
}

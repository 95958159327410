@import "../../assets/base.scss";

.notify {
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  z-index: 9999999;
}

.notifyItem {
  margin: 10px;
  min-width: 300px;
  max-width: 500px;
  border-radius: 5px;
  background: white;
  height: 100%;
  display: flex;
  box-shadow: 0px 0px 8px 2px #ccc;
  color: $gray;
  position: relative;

  animation: slide 1s ease-in-out;

  .close {
    position: absolute;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    border: 1px solid #ccc;
    top: calc(50% - 15px);
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 1px 1px #ccc;
    }

    &:after {
      content: "x";
      color: $light-gray;
      font-weight: bold;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .img {
    width: 70px;
    min-width: 70px;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: green;
    border-radius: 5px 0 0 5px;

    img {
      width: 50%;
      opacity: 0.3;
      height: 50%;
    }
  }

  .body {
    margin: 5px 25px 5px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      font-weight: bold;
      text-transform: capitalize;
      color: $green;
    }
    p {
      margin: 5px 0;
    }
  }

  &.error {
    .img {
      background: $dark-red;
    }

    .body {
      div {
        span {
          color: $dark-red;
        }
      }
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-500px);
  }
}

@import "../../../../assets/base.scss";

.profile {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  align-items: flex-start;

  @include mobile {
    grid-template-columns: 95%;
    margin: 0 auto;
  }

  .main {
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;

    .top {
      display: flex;
      padding: 40px;
      align-items: center;
      @include mobile {
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;
      }

      .image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: flex;
        background: $light-gray;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 8;
        @include mobile {
          align-self: center;
        }
        .img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          background: $light-gray;
          justify-content: center;
          align-items: center;
          z-index: 1;
          position: absolute;

          img {
            width: 100%;
            height: 100%;
          }

          .loading {
            height: 50px;
            position: absolute;
            top: 50px;
            left: 0px;
          }
        }

        input {
          width: 100%;
          visibility: hidden;
          z-index: 9;
          cursor: pointer;

          &:before {
            content: "";
            width: 35.5px;
            height: 35.5px;
            border-radius: 50%;
            position: absolute;
            background: white;
            right: 10px;
            visibility: visible;
            bottom: 0;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
            cursor: pointer;
          }

          &:after {
            content: "";
            width: 35.5px;
            height: 35.5px;
            border-radius: 50%;
            position: absolute;
            visibility: visible;
            right: 10px;
            bottom: 0;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
            background: url("../../../../assets/camera.svg") no-repeat center center;
            cursor: pointer;
          }
        }
      }

      .side {
        flex: 1;
        margin-left: 15px;

        @include mobile {
          margin: 0;
          width: 100%;
        }

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 12px 0;

          .grp {
            .lft {
              font-size: 12px;
              line-height: 15px;
              text-transform: capitalize;
              color: $darker-white;
            }

            .rgt {
              font-size: 16px;
              line-height: 20px;
              text-transform: capitalize;
              color: $fair-black;
            }

            .rgt__b {
              font-size: 16px;
              line-height: 20px;
              text-transform: capitalize;
              color: $darker-blue;
            }
          }
        }
      }
    }
  }

  .others {
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 40px;
    border-top: 0.5px solid $blush-white;

    @include mobile {
      padding: 24px 20px;
    }

    .head {
      font-size: 16px;
      line-height: 20px;
      color: $light-black;
    }

    .item {
      font-size: 16px;
      line-height: 18px;
      color: $blackish;
    }

    .title {
      font-size: 20px;
      line-height: 25px;
      color: $darker-blue;
    }

    .btn {
      button {
        font-size: 14px;
        line-height: 16px;
        color: white;
        background: $darker-blue;
        border-radius: 2px;
        padding: 7px 20px;
        border: none;
        outline: none;

        &:hover {
          background: lighten($darker-blue, 10);
        }
      }
    }
  }
}

@import "../../../assets/base.scss";

.home {
  padding: 5px;

  overflow: auto;
  display: flex;
  flex-direction: column;
  @include mobile {
    padding: 90px 0 0 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .welcome {
      color: $blue;
      font-size: 32px;
      line-height: 40px;
      text-transform: capitalize;
    }

    .time {
      display: flex;
      flex-direction: row;
      height: 38.59px;
      justify-content: center;
      align-items: center;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .date {
        padding: 8px;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        color: $blue;
        margin: 0 5px;
        background: rgba($blue, 0.1);
        border-radius: 5px;
      }
    }
  }

  .boost {
    width: 350px;
    margin: 30px 0;
    @include mobile {
      width: 90vw;
      margin: 30px auto;
    }
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      background: $blue;
      padding: 38px 20px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        width: 50%;
        height: 100%;
        transform: scale(1.1);
        opacity: 0.2;
        background: url(../../../assets/fun.svg) no-repeat;
      }
      .details {
        font-size: 14px;
        line-height: 19px;
        & > div {
          margin: 8px;
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    .card {
      background: $light-ash;
      border-radius: 10px;
      margin: 20px 0;

      .head {
        font-size: 16px;
        line-height: 20px;
        color: $light-black;
        font-weight: bold;
        padding: 13px;
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid $light-black;
      }

      .body {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 10px 0;
        img {
          margin: 20px;
          cursor: pointer;
          transition-duration: 1s;

          &:hover {
            transform: scale(1.2);
          }
        }

        p {
          text-align: center;
          font-size: 14px;
          color: $light-black;
          line-height: 18px;
        }
      }
    }
  }

  .progress {
    width: 105%;
    margin: 20px 0;
    appearance: none;
    height: 4px;

    &[value] {
      background-color: $blush-white;
      border-radius: 4px;
    }
    &[value]::-webkit-progress-bar {
      background-color: $blush-white;
      border-radius: 4px;
    }

    &[value]::-webkit-progress-value {
      background-color: $blue;
      border-radius: 4px;
    }
    &[value]::-moz-progress-bar {
      background-color: $blue;
      border-radius: 4px;
    }
  }

  .cardCollect {
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: center;
    align-items: center;

    .main {
      width: 350px;
      text-align: center;
      // box-sizing: border-box;
      .title {
        font-size: 24px;
        color: $blackish;
        line-height: 22px;
        margin: 20px 0;
      }
      .sub {
        font-size: 16px;
        line-height: 22px;
        color: $light-black;
        margin-bottom: 30px;
      }
      .two {
        display: grid;
        grid-template-columns: 45% 45%;
        grid-gap: 10%;
      }
      .input {
        margin: 15px 0;
        label {
          color: $light-black;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 1.125px;
          text-transform: uppercase;
          display: block;
          text-align: left;
        }
        input {
          appearance: none;
          width: 100%;
          font-size: 15px;
          line-height: 19px;
          color: $light-black;
          border-radius: 5px;
          background: $card-input-bg;
          border: 1px solid $blush-gray;
          padding: 10px;
          outline: none;
          letter-spacing: 2px;
          &:focus {
            border: 1px solid $white-blue;
          }
          &.error {
            // &:empty {
            //   border: 1px solid $dark-red;
            // }
            &:focus {
              border: 1px solid $dark-red;
            }
          }
          &::placeholder {
            color: $light-gray;
          }
        }

        button {
          width: 105%;
          padding: 14px;
          font-size: 14px;
          line-height: 16px;
          outline: none;
          color: white;
          background: $blue;
          border-radius: 4px;
          border: none;
          font-weight: bold;
          cursor: pointer;

          &:hover,
          &:focus {
            background: lighten($blue, 10);
          }
        }
      }
    }
  }

  .dash {
    display: flex;

    @include mobile {
      flex-direction: column;
    }
    .left {
      width: 330px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      position: relative;
      @include mobile {
        width: 95vw;
      }

      .pill {
        cursor: pointer;
        background: $darker-blue;
        color: white;
        padding: 20px;
        width: 100%;
        box-shadow: 0px 7px 9px rgba(47, 117, 247, 0.16);
        border-radius: 4px;
        margin: 5px 0;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 70% 15% 5%;
        justify-content: space-between;
        align-items: center;

        &.darkblue {
          background: $darkest-blue;
        }

        &.green {
          background: $dark-green;
        }
        &.lightblue {
          background: $white-blue;
        }

        &:hover {
          transform: translateX(5px);
        }
        .per {
          font-size: 12px;
          line-height: 15px;
          font-weight: bolder;
          padding: 13px 6px;
          border-radius: 50%;
          display: flex;
          align-content: center;
          justify-content: center;
          position: relative;
          z-index: 9;

          @include mobile {
            padding: 35% 0px;
          }

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            opacity: 0.3;
            height: 100%;
            background: white;
            border-radius: 50%;
            z-index: -1;
            top: 0;
            left: 0;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          font-size: 24px;
          line-height: 30px;
          font-weight: bolder;
          span {
            font-size: 10px;
            line-height: 13px;
            opacity: 0.8;
            text-transform: capitalize;
          }
        }
      }

      .save {
        background: white;
        padding: 15px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin: 20px 0;
        .top {
          font-size: 20px;
          line-height: 100%;
          color: $blackish;
          margin: 10px auto;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
        .sub {
          font-size: 14px;
          line-height: 18px;
          color: $light-black;
          margin: 5px auto;
        }

        form {
          display: flex;
          flex-direction: column;
          justify-items: center;
          margin: 20px auto;
          label {
            font-size: 14px;
            line-height: 18px;
            color: $light-black;
            text-align: left;
          }

          input {
            background: $input-blue;
            mix-blend-mode: normal;
            border-radius: 2px;
            box-sizing: border-box;
            padding: 14px 10px;
            width: 100%;
            border: none;
            margin: 10px auto;
            font-size: 15px;
            line-height: 18px;
            color: $new-black;
            &[type="submit"] {
              background: $darker-blue;
              color: white;
              border-radius: 4px;
              cursor: pointer;
              &:hover {
                background: lighten($darker-blue, 10);
              }
            }
          }
        }
      }
      .back {
        font-size: 14px;
        text-transform: capitalize;
        cursor: pointer;
        font-weight: bold;
        color: $light-gray;
        background: none;
        border: none;
        text-align: left;
        padding: 20px 0;
        outline: none;
        &:hover {
          color: $dark-red;
        }
      }

      .card {
        background: white;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        box-sizing: border-box;

        .title {
          font-size: 20px;
          line-height: 21px;
          color: $light-black;
          padding: 20px;
          font-weight: bold;
        }

        .bud {
          padding: 20px;
          border-bottom: 1px solid rgba($purple, 0.1);

          .top {
            font-size: 12px;
            line-height: 15px;
            color: $blue;
            padding-bottom: 14px;
          }

          .topOrange {
            font-size: 12px;
            line-height: 15px;
            color: $orange;
            padding-bottom: 14px;
          }
          .bold {
            line-height: 30px;
            font-size: 24px;
            color: $last-black;
            font-weight: bold;
          }

          .normal {
            font-size: 16px;
            line-height: 20px;
            color: $last-black;
          }
        }
      }
    }
    .right {
      flex: 1;
      padding: 5px 15px 15px;
      display: flex;
      flex-direction: column;

      @include mobile {
        padding: 5px 0;
      }

      .bars {
        display: flex;
        margin-bottom: 10px;
        @include mobile {
          flex-direction: column;
          align-items: center;
        }

        .chart {
          width: 338.11px;
          height: 232.7px;
          margin-right: 10px;
          background: white;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          @include mobile {
            width: 90vw;
            margin: 0;
          }
          .top {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            font-size: 14px;
            line-height: 18px;
            color: $light-black;
            padding: 5px;

            .wrapper {
              flex: 1;
              position: relative;

              .point {
                font-size: 32px;
                line-height: 40px;
                color: $blue;
                position: absolute;
                left: 25%;
                top: 70%;
                text-align: center;
                width: 50%;
              }
            }
            span {
              padding: 15px;
            }
          }
          .bottom {
            border-top: 1px solid $blush-white;
            padding: 20px;
            text-align: center;
            color: $light-black;
          }
        }

        .ref {
          background: white;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
          border-radius: 5px;
          flex: 1;
          padding: 15px;
          box-sizing: border-box;
          @include mobile {
            width: 90vw;
            margin: 0;
            margin-top: 10px;
          }
          .top {
            font-size: 16px;
            line-height: 21px;
            color: $orange;
            margin-bottom: 5px;
          }
          .sub {
            font-size: 14px;
            line-height: 18px;
            color: $light-black;
            margin-bottom: 5px;
          }
          form {
            input {
              background: $input-blue2;
              mix-blend-mode: normal;
              border: 1px solid $blue;
              border-radius: 2px;
              padding: 15px;
              box-sizing: border-box;
              width: 100%;
              font-size: 12px;
              line-height: 16px;
              opacity: 0.5;
              margin: 5px 0;
              &[type="submit"] {
                background: $darker-blue;
                color: white;
                border-radius: 4px;
                cursor: pointer;
                opacity: 1;
                border: none;
                font-size: 14px;
                line-height: 18px;
                &:hover {
                  background: lighten($darker-blue, 10);
                }
              }
            }
          }
        }
      }

      .table {
        flex: 1;
        background: white;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        box-sizing: border-box;
        @include mobile {
          widows: 80vw;
          overflow: auto;
        }
      }
    }
  }
}

@import "../../assets/base.scss";
.progress {
  width: 105%;
  margin: 20px 0;
  appearance: none;
  height: 4px;

  &[value] {
    background-color: $blush-white;
    border-radius: 4px;
  }
  &[value]::-webkit-progress-bar {
    background-color: $blush-white;
    border-radius: 4px;
  }

  &[value]::-webkit-progress-value {
    background-color: $blue;
    border-radius: 4px;
  }
  &[value]::-moz-progress-bar {
    background-color: $blue;
    border-radius: 4px;
  }
}
.cardCollect {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;

  .main,
  .mainI {
    width: 350px;
    text-align: center;
    // box-sizing: border-box;
    .title {
      font-size: 24px;
      color: $blackish;
      line-height: 22px;
      margin: 20px 0;
    }
    .sub {
      font-size: 16px;
      line-height: 22px;
      color: $light-black;
      margin-bottom: 30px;
    }
    .two {
      display: grid;
      grid-template-columns: 45% 45%;
      grid-gap: 10%;
    }

    .input {
      margin: 15px 0;
      label {
        color: $light-black;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1.125px;
        text-transform: uppercase;
        display: block;
        text-align: left;
      }
      input {
        appearance: none;
        width: 100%;
        font-size: 15px;
        line-height: 19px;
        color: $light-black;
        border-radius: 5px;
        background: $card-input-bg;
        border: 1px solid $blush-gray;
        padding: 10px;
        outline: none;
        letter-spacing: 2px;
        &:focus {
          border: 1px solid $white-blue;
        }
        &.error {
          // &:empty {
          //   border: 1px solid $dark-red;
          // }
          &:focus {
            border: 1px solid $dark-red;
          }
        }
        &::placeholder {
          color: $light-gray;
        }
      }

      button {
        width: 105%;
        padding: 14px;
        font-size: 14px;
        line-height: 16px;
        outline: none;
        color: white;
        background: $blue;
        border-radius: 4px;
        border: none;
        font-weight: bold;
        cursor: pointer;

        &:hover,
        &:focus {
          background: lighten($blue, 10);
        }

        &.exit {
          width: 25%;
          background: none;
          color: $light-gray;
          padding-left: 20px;
          &:hover,
          &:focus {
            color: white;
            padding: auto 20px;
            background: lighten($orange, 10);
          }
        }
      }
    }
  }
  .mainI {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    iframe {
      width: 80%;
      height: 50vh;
    }
    .progress {
      width: 100%;
    }
    .input {
      width: 50%;
      display: flex;
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }
}

@font-face {
  font-family: "CircularStd";
  font-weight: normal;
  src: url(./assets/fonts/circular-book.otf) format("opentype");
}

@font-face {
  font-family: "CircularStd";
  font-weight: bold;
  src: url(./assets/fonts/circular-bold.otf) format("opentype");
}

body {
  font-family: CircularStd, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

@import "../../assets/base.scss";

.about {
  min-height: 100vh;
  width: 100vw;

  .main {
    padding-top: 70px;
    background: $lighter-blue;
    height: 93vh;
    display: flex;
    position: relative;
    @include mobile {
      padding: 5px;
      height: 30vh;
    }

    .text {
      font-size: 40px;
      line-height: 120%;
      color: $dark-gray;
      margin: 150px 100px;
      font-weight: bold;
      width: 466px;

      @include mobile {
        margin: 90px 20px;
        font-size: medium;
        width: 100vw;
      }
    }

    .decor {
      position: absolute;
      width: 80%;
      height: 570px;
      right: 0;
      bottom: -120px;
      z-index: 9;
      background: $gray;
      overflow: hidden;
      @include mobile {
        height: 60%;
        bottom: -70px;
      }
      img {
        width: 100%;
      }
    }
  }

  .others {
    padding-top: 200px;
    @include mobile {
      padding-top: 100px;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 5px;
      @include mobile {
        flex-direction: column;
      }
      .image {
        width: 587px;
        height: 500px;
        overflow: hidden;
        @include mobile {
          width: 95vw;
          height: 50vh;
        }
        img {
          height: 100%;
        }
      }

      .text {
        width: 588px;
        margin-left: 100px;
        @include mobile {
          width: 95vw;
          margin: 10px 0;
          text-align: justify;
        }
        .title {
          font-size: 24px;
          line-height: 120%;
          color: $dark-gray;
          margin-bottom: 24px;
          font-weight: bold;
          @include mobile {
            font-size: 18px;
          }
        }

        .desc {
          font-size: 20px;
          line-height: 40px;
          color: $dark-gray;
          @include mobile {
            font-size: 14px;
          }
        }
      }
    }

    .item2 {
      background: $lighter-blue;
      height: 469px;
      margin-bottom: 50px;
      position: relative;
      display: flex;
      align-items: center;
      @include mobile {
        flex-direction: column;
        margin: 0;
        height: 100%;
      }
      .text {
        width: 588px;
        margin-left: 100px;
        @include mobile {
          width: 95vw;
          margin: 10px;
        }
        .title {
          font-size: 24px;
          line-height: 120%;
          color: $dark-gray;
          margin-bottom: 24px;
          font-weight: bold;
          @include mobile {
            font-size: 18px;
          }
        }

        .desc {
          font-size: 20px;
          line-height: 40px;
          color: $dark-gray;
          @include mobile {
            font-size: 14px;
            text-align: justify;
          }
        }
      }

      .image {
        position: absolute;
        right: 0;
        top: 90px;
        width: 587px;
        height: 500px;
        overflow: hidden;
        @include mobile {
          display: none;
        }
        img {
          height: 100%;
        }
      }
    }

    .footDesc {
      font-size: 24px;
      line-height: 40px;
      padding: 200px 100px 100px 100px;
      font-weight: bold;

      @include mobile {
        padding: 20px;
        font-size: 14px;
        text-align: justify;
      }
    }

    .banner {
      height: 469px;
      @include mobile {
        height: 30vh;
      }
    }

    .bottom {
      padding: 5%;

      .text {
        font-size: 24px;
        line-height: 40px;
        color: $dark-gray;
      }

      .gridSec {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(580px, 1fr));
        margin: 100px 0;
        grid-gap: 25px;
        @include mobile {
          margin: 20px 0;
          grid-template-columns: auto;
        }
        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 580px;
          @include mobile {
            width: 90vw;
            text-align: justify;
          }

          .title {
            display: flex;
            font-size: 24px;
            line-height: 40px;
            color: $dark-gray;
            font-weight: bold;
            margin: 30px 0;
            align-items: center;
            @include mobile {
              font-size: 18px;
            }

            div {
              align-self: center;
              margin-top: 10px;
            }

            .big {
              font-size: 80px;
              line-height: 40px;
              color: $blue;
              opacity: 0.4;
              margin-top: 0;
              margin-right: 15px;
              @include mobile {
                font-size: 50px;
              }
            }
          }

          .desc {
            font-size: 20px;
            line-height: 40px;
            color: $dark-gray;
            @include mobile {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .noBg {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  .blueBtn {
    font-size: 18px;
    line-height: 120%;
    color: white;
    background: $darker-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 13px 18px;
  }
}

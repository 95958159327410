@import "../../assets/base.scss";

.blog {
  min-height: 100vh;
  width: 100vw;

  .main {
    .back {
      height: 600px;
      background-size: cover;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 5%;
      position: relative;
      transition-duration: 1s;
      @include mobile {
        height: 100vh;
      }
      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: rgba(#000, 0.3);
        top: 0;
        left: 0;
      }
      & > div {
        z-index: 2;
      }

      .title {
        font-size: 40px;
        line-height: 120%;
        color: white;
        margin-bottom: 80px;
        @include mobile {
          font-size: 20px;
          margin: 80px 0;
        }
      }
    }

    .searchbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 8%;
      border-bottom: 0.5px solid #c4c4c4;

      @include mobile {
        padding: 10px 3%;
        flex-direction: column;
      }
      .text {
        font-size: 18px;
        line-height: 22px;
        color: black;
        @include mobile {
          align-self: flex-start;
          margin: 0 15px;
        }
      }

      input {
        width: 302px;
        background: white;
        border-radius: 4px;
        border: 1px solid $light-gray;
        font-size: 16px;
        line-height: 22px;
        border-radius: 4px;
        padding: 10px;
        outline-color: transparent;
        margin: 10px;
        color: $gray;

        &::placeholder {
          font-size: 16px;
          line-height: 22px;
          color: $lighter-gray;
        }

        &[type="submit"] {
          width: auto;
          padding: 10px 33px;
          background: $darker-blue;
          border: none;
          color: white;
          cursor: pointer;
          @include mobile {
            padding: 10px;
            font-size: 12px;
          }
        }

        &:focus {
          outline-color: $darker-blue;
        }
      }
    }
  }

  .articles {
    display: grid;
    grid-gap: 70px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    padding: 7%;
    @include mobile {
      grid-template: 90%;
      grid-gap: 15px;
      padding: 2%;
    }

    .article {
      width: 88%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mobile {
        width: 100%;
      }
      .image {
        width: 100%;
        border-radius: 4px;
        height: 245px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          transform: scale(1.05);
        }
      }

      .date {
        font-size: 14px;
        line-height: 22px;
        color: black;
        margin: 8px;
        align-self: center;
        font-weight: bold;
        @include mobile {
          font-size: 10px;
        }
      }

      .desc {
        font-size: 18px;
        line-height: 25px;
        color: $dark-gray;
        margin: 12px 0;
        @include mobile {
          font-size: 12px;
          margin: 0px 0 10px 0;
          text-align: center;
        }
      }

      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: black;
        margin: 12px 0;
        @include mobile {
          font-size: 12px;
          margin: 0px 0 5px 0;
        }
      }
    }
  }

  .loadmore {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;
  }

  .noBg {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  .blueBtn {
    font-size: 18px;
    line-height: 22px;
    color: white;
    background: $darker-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 13px 18px;
    @include mobile {
      font-size: 12px;
    }

    &:hover {
      background: lighten($darker-blue, 10);
    }
  }
}

@import "../../assets/base.scss";

.sidebar {
  width: 200px;
  display: flex;
  flex-direction: column;
  @include mobile {
    display: none;
  }

  a {
    font-size: 18px;
    line-height: 14px;
    text-decoration: none;
    color: $dark-white;
    text-transform: capitalize;
    padding: 12px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    .activeIcon {
      display: none;
    }

    img {
      margin-right: 10px;
    }

    &.active {
      background: white;
      border-radius: 4px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
      color: $blackish;

      .activeIcon {
        display: inline-block;
      }

      .inactiveIcon {
        display: none;
      }
    }
  }
}

@import "../../assets/base.scss";

.signup {
  min-height: 100vh;
  width: 100vw;

  .main {
    background: white;
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    @include mobile {
      grid-template-columns: 100%;
    }
    .left {
      height: 100%;
      position: relative;

      .banner {
        height: 95%;
        display: flex;
        justify-content: center;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
        align-items: center;
        z-index: 99;
        position: relative;
        background: $lighter-blue;

        & > div {
          width: 359px;
          @include mobile {
            padding-top: 90px;
            width: 95vw;
            min-height: 350px;
          }
        }
      }

      .text {
        font-size: 40px;
        line-height: 120%;
        color: $dark-gray;
        font-weight: bolder;
        margin: 7px;
        @include mobile {
          margin-bottom: 25px;
        }
      }

      .text2 {
        font-size: 20px;
        line-height: 22px;
        color: $dark-gray;
        margin: 8px;
        @include mobile {
          margin-bottom: 25px;
        }
      }

      .text3 {
        font-size: 16px;
        line-height: 22px;
        color: $dark-gray;
        margin: 8px;

        a {
          color: $darker-blue;
          text-decoration: none;
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 99%;
        top: 0;
        left: -30px;
        background: $darker-blue;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 99%;
        top: -8px;
        right: 38px;
        background: white;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
      }
    }

    .right {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      @include mobile {
        margin: 0 auto;
        width: 95vw;
      }
      .formGroup {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 470px;

        .head {
          font-size: 20px;
          color: $gray;
          line-height: 22px;
          margin: 20px 5px;

          a {
            color: $blue;
            text-decoration: none;
          }
        }

        .headDash {
          font-size: 20px;
          color: $gray;
          line-height: 22px;
          margin: 5px;
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;

          p {
            background: white;
            padding: 0 10px;
            z-index: 99;
          }

          hr {
            position: absolute;
            top: 20px;
            width: 100%;
          }
        }

        .social {
          width: 470px;
          display: grid;
          grid-template-columns: 48% 48%;
          grid-gap: 4%;

          .googleBtn {
            background: #fff;
            border: 1px solid $google-red;
            border-radius: 4px;
            padding: 19px 0;
            font-size: 20px;
            line-height: 22px;
            cursor: pointer;
            color: $google-red;

            &:focus {
              outline-color: $google-red;
            }

            i {
              font-weight: bolder;
              margin-right: 10px;
            }
          }

          .faceBtn {
            background: #fff;
            border: 1px solid $face-blue;
            border-radius: 4px;
            padding: 19px 0;
            font-size: 20px;
            line-height: 22px;
            cursor: pointer;
            color: $face-blue;

            i {
              font-weight: bolder;
              margin-right: 10px;
            }

            &:focus {
              outline-color: $face-blue;
            }
          }
        }

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          input {
            background: white;
            border: 1px solid $dark-white;
            border-radius: 4px;
            padding: 19px 5%;
            margin: 13px 0;
            font-size: 16px;
            line-height: 22px;
            color: $dark-gray;
            width: 90%;
            position: relative;

            &:placeholder {
              font-size: 16px;
              line-height: 22px;
              color: $placeholder;
              margin-left: 50px;
            }

            &:focus {
              outline-color: $blue;
            }
          }
          .submit {
            border-radius: 4px;
            margin: 13px;
            font-size: 16px;
            line-height: 22px;
            padding: 5px 50px;
            width: 100%;
            background: $darker-blue;
            color: white;
            cursor: pointer;
            &:disabled {
              background: lighten($darker-blue, 10);
              cursor: no-drop;
            }
            span {
              padding: 12px 0;
            }
            img {
              height: 35px;
            }

            &:hover {
              background: lighten($darker-blue, 10);
            }
          }
          .forgot {
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
            color: $darker-blue;
            font-weight: bolder;
            margin: 5px 13px;
          }
        }
      }
    }
  }

  .noBg {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  .blueBtn {
    font-size: 18px;
    line-height: 120%;
    color: white;
    background: $darker-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 13px 18px;
  }
}

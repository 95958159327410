@import '../../assets/base.scss';

.notfound {
    min-height: 100vh;
    width: 100vw;

    .main {
        padding-top: 70px;
        background: linear-gradient(119.51deg, $light-blue 0%, #fff8ff 48.54%);
        height: 93vh;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .text {
            font-size: 30px;
            color: $gray;
        }

        .not {
            font-size: 300px;
            font-weight: bolder;
            display: flex;
            color: $deep-blue;

            div {
                margin: 25px;
            }
        }
    }

    .blueBtn {
        font-size: 18px;
        line-height: 120%;
        color: white;
        background: $darker-blue;
        border-radius: 4px;
        margin: 0 auto;
        border: none;
        cursor: pointer;
        padding: 13px 18px;
        text-decoration: none;

        &:hover {
            background: lighten($darker-blue, 5);
        }
    }
}

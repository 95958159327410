@import "../../assets/base.scss";

.nav {
  padding: 15px 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @include mobile {
    padding: 15px 10px;
  }

  .mobileMenu {
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    width: 100vw;
    height: 150vh;
    .head {
      width: 100%;
      height: 50px;
      padding-right: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 25px;
        margin: 20px;
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        font-size: 24px;
        line-height: 80px;
        color: $gray;
        text-decoration: none;
        &.active {
          color: $blue;
        }
      }
    }
  }

  &.background {
    background: white;
    border-bottom: 1px solid $blush-white;
  }

  .logo {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 21px;
    color: $light-black;
    @include mobile {
      font-size: 10px;
    }

    img {
      margin-right: 10px;
    }
  }

  .mobilelinks {
    display: none;
    @include mobile {
      display: flex;
    }
  }
  .links {
    display: flex;
    justify-content: space-between;
    width: 50vw;

    @include mobile {
      display: none;
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: $gray;
      transition-duration: 0.5s;
      margin: 0 10px;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      display: flex;
      align-items: center;
      position: relative;
      @include mobile {
        margin: 0;
      }
      &:hover {
        color: $blue;
      }

      &.active {
        &:after {
          content: "";
          position: absolute;
          border: 1px solid $darker-blue;
          width: 9px;
          left: calc(50% - 4.5px);
          bottom: -5px;
        }
      }

      &.light {
        color: white;
      }

      i {
        font-size: 10px;
        margin: 0 5px;
        font-weight: bolder;
      }

      .drop {
        padding: 5px 2px;
        color: $gray;
        position: absolute;
        bottom: -75px;
        left: -15px;
        background: $ash;
        border-radius: 4px;
        z-index: 999;
        box-shadow: 0 0 4px 2px $lighter-gray;

        a {
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }
    }
  }

  .dashmobile {
    display: none;

    @include mobile {
      display: flex;
    }
  }
  .dashlinks {
    display: flex;

    @include mobile {
      display: none;
    }

    a {
      text-transform: capitalize;
      text-decoration: none;
      color: $light-black;
      transition-duration: 0.5s;
      margin: 0 10px;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      align-items: center;
      position: relative;
      background: #ffffff;
      border: 1px solid $blue;
      border-radius: 4px;
      padding: 10px;
      justify-content: center;
      vertical-align: middle;

      i {
        font-size: 10px;
        margin: 0 10px;
        font-weight: bolder;
      }
    }

    .drop {
      padding: 5px;
      color: $gray;
      position: absolute;
      bottom: -120px;
      left: 0%;
      min-width: 100%;
      background: $ash;
      border-radius: 4px;
      z-index: 999;
      box-shadow: 0 0 4px 2px $lighter-gray;
      display: flex;
      flex-direction: column;
      justify-content: center;

      a {
        border: none;
        background: none;
        margin-bottom: 5px;
        margin-top: 5px;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}

@import "../../../../assets/base.scss";

.withdraw {
  flex: 1;
  @include mobile {
    // width: 80vw;
  }
  .group {
    display: flex;
    @include mobile {
      flex-direction: column;
      // width: 90vw;
    }
  }

  .left {
    width: 350px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    background: white;
    padding: 40px 18px;
    @include mobile {
      width: 85vw;
      margin: 0 auto 20px auto;
    }
    .title {
      font-size: 24px;
      line-height: 30px;
      color: $light-black;
      margin-bottom: 15px;
    }

    .sub {
      font-size: 12px;
      line-height: 18px;
      margin: 5px 0 35px 0;
      color: $blue;
    }

    .instruct {
      font-size: 14px;
      line-height: 18px;
      color: $light-black;
    }

    .form {
      margin: 20px 0;

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        img {
          height: 50px;
        }
        input {
          padding: 15px;
          border: none;
          background: $input-blue;
          margin: 10px 0;
          font-size: 15px;
          line-height: 18px;
          color: $new-black;
          outline: none;
          border-radius: 4px;
          width: 91%;

          &[type="submit"] {
            background: $darker-blue;
            color: white;
            font-weight: bold;
            cursor: pointer;

            width: 100%;
          }
          &::placeholder {
            opacity: 0.5;
          }

          &:focus {
            border: 1px solid $blue;
          }
        }
      }
    }
  }

  .right {
    margin-left: 10px;
    flex: 1;
    @include mobile {
      margin: 0 auto;
      width: 90vw;
    }

    .table {
      @include mobile {
        width: 90vw;
        overflow: auto;
      }
    }
  }
}

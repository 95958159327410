@import "../../assets/base.scss";

.board {
  height: 100vh;
  width: 100vw;
  position: relative;
  .overall {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.5);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      background: white;
      border-radius: 4px;
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text {
        font-size: 16px;
        line-height: 22px;
        color: $light-black;
        width: 75%;
      }
      .links {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        line-height: 22px;
        a {
          color: $blue;
          margin: 5px;
        }
      }

      .btn {
        color: white;
        background: $blue;
        padding: 15px;
        width: 80%;
        border-radius: 4px;
        font-size: 20px;
        box-sizing: border-box;
        line-height: 25px;
        cursor: pointer;
        outline: none;
        &:hover {
          background: lighten($blue, 10);
        }
      }

      .linkbtn {
        font-size: 20px;
        line-height: 25px;
        color: $blue;
        background: none;
        border: none;
        margin: 10px;
        cursor: pointer;
        outline: none;
        &:hover {
          color: $orange;
        }
      }
    }
  }

  .main {
    padding-top: 75px;
    background: white;
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .frame {
      width: 85%;
      height: 100%;
      overflow-x: auto;
      @include mobile {
        width: 90%;
      }
      &::-webkit-scrollbar {
        display: none;
      }

      .welcome {
        border-bottom: 0.25px solid $dark-gray;

        .name {
          color: $dark-gray;
          font-size: 24px;
          line-height: 22px;
          font-weight: bolder;
          margin: 15px 0;
        }

        .desc {
          font-size: 20px;
          line-height: 22px;
          color: $dark-gray;
          margin: 20px 0 40px 0;
        }
      }

      .transition {
        margin-top: 40px;

        .title {
          color: $orange;
          font-size: 28px;
          line-height: 35px;
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 120px;
          position: absolute;
          bottom: 80px;
          width: 70%;
          @include mobile {
            bottom: -20px;
            background: white;
            height: 40px;
          }

          .next {
            font-size: 20px;
            line-height: 25px;
            border: none;
            background: none;
            color: $darker-blue;
            align-self: flex-end;
            outline: none;
            cursor: pointer;

            img {
              height: 50px;
            }

            &:hover {
              color: lighten($darker-blue, 10);
            }
          }

          .prev {
            font-size: 20px;
            line-height: 25px;
            border: none;
            background: none;
            outline: none;
            cursor: pointer;
            color: $darker-blue;

            &:hover {
              color: lighten($darker-blue, 10);
            }
          }
        }

        .box {
          margin-top: 20px;

          .two {
            display: grid;
            grid-template-columns: 50% 50%;
            margin: 30px 0;
            @include mobile {
              grid-template-columns: 100%;
              width: 85vw;
            }
          }

          .smallText {
            font-size: 16px;
            color: $dark-gray;
            line-height: 20px;
            @include mobile {
              width: 90vw;
            }
          }

          .text {
            font-size: 20px;
            line-height: 25px;
            color: $dark-gray;
            width: 466px;
            text-align: justify;
            @include mobile {
              width: 80vw;
            }

            .optional {
              font-size: 16px;
              line-height: 20px;
              margin: 10px 0;
              color: $green;
            }
          }

          .divide {
            display: grid;
            grid-template-columns: 55% 45%;
            align-items: center;
            grid-gap: 40px;
            margin: 0 0 30px 0;

            @include mobile {
              grid-template-columns: 100%;
              margin-top: 10px;
            }

            .text {
              font-size: 16px;
              line-height: 20px;
              color: $light-black;
              font-weight: bold;
            }

            .cash {
              font-size: 28px;
              line-height: 35px;
              color: $darker-blue;
              font-weight: bold;
            }

            .date {
              font-size: 28px;
              line-height: 35px;
              font-weight: bold;
              color: $green;
            }
          }

          .btns,
          .btnfull {
            display: flex;
            flex-direction: column;
            width: 80%;
            align-items: center;

            @include mobile {
              width: 50%;
              align-items: flex-start;
            }

            .btn {
              width: 100%;
              background: $darker-blue;
              border: 1px solid $darker-blue;
              border-radius: 4px;
              font-size: 20px;
              line-height: 25px;
              color: white;
              padding: 17px;
              margin: 10px 0;
              outline: none;
              cursor: pointer;

              @include mobile {
                width: 93%;
              }

              &:hover {
                background: lighten($darker-blue, 10);
              }
            }

            .link {
              font-size: 20px;
              line-height: 25px;
              color: $darker-blue;
              border: none;
              background: none;
              margin: 10px 0;
              outline: none;
              cursor: pointer;

              &:hover {
                color: lighten($darker-blue, 10);
              }
            }
          }

          .btnfull {
            width: 100%;
            margin: 40px 10px;
            width: 470px;
            @include mobile {
              width: 90vw;
              margin: 20px 0;
            }
          }

          input {
            border: 1px solid $dark-white;
            border-radius: 4px;
            padding: 17px 24px;
            width: 420px;
            font-size: 20px;
            line-height: 25px;
            color: $dark-gray;
            margin: 0 10px;
            outline: none;

            @include mobile {
              width: 83%;
              margin: 0;
            }

            &::placeholder {
              font-size: 20px;
              line-height: 25px;
              color: $placeholder;
            }

            &:focus {
              border-color: $darker-blue;
            }
          }

          .select {
            border-radius: 4px;
            width: 470px;
            font-size: 20px;
            line-height: 25px;
            color: $dark-gray;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 10px;
            height: 60px;

            @include mobile {
              width: 100%;
              margin: 0;
            }
          }

          .sum {
            border-radius: 3px;
            width: 470px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 20px 10px;
            background: $blue;
            color: white;
            padding: 25px 44px;
            box-sizing: border-box;
            @include mobile {
              width: 83vw;
              margin: 20px 0;
            }

            .sumText {
              font-size: 16px;
              line-height: 20px;
            }
            .sumCash {
              font-size: 32px;
              line-height: 40px;
              font-weight: bold;

              border-radius: 4px;
              padding: 20px;
              text-align: center;
              margin-top: 20px;
              position: relative;
              @include mobile {
                font-size: 20px;
                padding: 20px 5px;
                text-align: center;
              }
              span {
                mix-blend-mode: hard-light;
              }
              &:before {
                position: absolute;
                background: $blue;
                mix-blend-mode: multiply;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
}

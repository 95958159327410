@import "../../assets/base.scss";

.contact {
  min-height: 100vh;
  width: 100vw;

  .main {
    padding-top: 250px;
    padding-left: 5%;
    background: $lighter-blue;
    height: 75vh;
    display: flex;
    position: relative;

    @include mobile {
      padding-top: 70px;
      height: 120vh;
    }

    .title {
      font-size: 40px;
      line-height: 120%;
      color: $dark-gray;
      font-weight: bold;
      margin-bottom: 40px;
      @include mobile {
        font-size: 20px;
      }
    }

    .places {
      display: flex;
      @include mobile {
        flex-direction: column;
      }
      .place {
        display: flex;
        align-items: center;
        img {
          margin-bottom: 15px;
          @include mobile {
            margin-right: 5px;
          }
        }
        width: 220px;
        margin-right: 25px;
        font-size: 18px;
        line-height: 22px;
        color: $dark-gray;
        @include mobile {
          margin-bottom: 5px;
        }
      }
    }

    .formGroup {
      width: 65%;
      top: 67%;
      left: 8%;
      z-index: 20;
      position: absolute;
      @include mobile {
        width: 100%;
        top: 45%;
        left: 2%;
      }
      .map {
        width: 466px;
        height: 400px;
        position: absolute;
        top: -30px;
        right: -30%;
        @include mobile {
          z-index: -1;
          top: -60px;
          left: -5%;
          width: 50%;
          overflow: hidden;
        }
      }

      .form {
        background: white;
        color: $dark-gray;
        padding: 40px;

        @include mobile {
          padding: 10px;
        }
        &:before {
          content: "";
          width: 290px;
          height: 250px;
          background: $darker-blue;
          position: absolute;
          top: -30px;
          left: -30px;
          z-index: -10;
          @include mobile {
            display: none;
          }
        }

        .text {
          font-size: 24px;
          line-height: 120%;
        }

        .subtext {
          font-size: 16px;
          line-height: 22px;
        }

        form {
          width: 75%;
          margin-top: 55px;
          @include mobile {
            width: 100%;
          }
          .split {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 20px;
            margin-bottom: 30px;
            @include mobile {
              grid-template-columns: auto;
            }
          }

          .group {
            display: flex;
            flex-direction: column;

            label {
              font-size: 16px;
              line-height: 22px;
              color: $dark-gray;
              margin-bottom: 10px;
            }

            input {
              background: white;
              border: 1px solid $lighter-gray;
              border-radius: 4px;
              padding: 15px;
              font-size: 20px;
              color: $dark-gray;
              @include mobile {
                width: 85%;
              }
              &:focus {
                outline-color: $blue;
              }
            }

            textarea {
              background: white;
              border: 1px solid $lighter-gray;
              border-radius: 4px;
              padding: 15px;
              font-size: 20px;
              color: $dark-gray;
              @include mobile {
                width: 85%;
              }
              &:focus {
                outline-color: $blue;
              }
            }
          }

          .btn {
            display: flex;
            margin: 40px 0;
            flex-direction: column;
            align-items: flex-end;
            @include mobile {
              margin-right: 20px;
            }
            button {
              font-size: 14px;
              line-height: 22px;
              padding: 10px 40px;
              background: $darker-blue;
              color: white;
              border-radius: 4px;
              cursor: pointer;

              &:hover {
                background: lighten($darker-blue, 10);
              }
            }
          }
        }
      }
    }
  }

  .padbottom {
    height: 580px;
  }

  .noBg {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  .blueBtn {
    font-size: 18px;
    line-height: 120%;
    color: white;
    background: $darker-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 13px 18px;
  }
}

@import "../../../../assets/base.scss";

.upload {
  flex: 1;
  @include mobile {
    height: 80vh;
  }
  .topPart {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    @include mobile {
      width: 95vw;
    }
    & > div {
      display: flex;
    }

    button {
      background: $dark-ash;
      border: 1px solid $blue;
      border-radius: 4px;
      padding: 12px 15px;
      font-size: 14px;
      line-height: 21px;
      color: $gray;
      margin: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;

      i {
        margin: 0 5px;
      }
      &:hover {
        background: darken($dark-ash, 5);
      }
      .icon {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: $darker-blue;
        margin: 0 15px;
        position: relative;

        &:before {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background: $darker-blue;
          position: absolute;
          left: 5px;
          top: -5px;
        }

        &:after {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background: $darker-blue;
          position: absolute;
          left: 10px;
        }
      }
    }
  }

  .table {
    @include mobile {
      width: 95vw;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .modal {
    z-index: 9999;
    position: fixed;
    top: 0;
    width: 105vw;
    left: 0;
    height: 105vh;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    .back {
      position: absolute;
      top: 0;
      bottom: 0;
      background: rgba(black, 0.8);
      left: 0;
      right: 0;
    }
    .content {
      padding: 20px;
      background: white;
      border-radius: 5px;
      z-index: 99999;
      min-width: 40%;
      @include mobile {
        width: 80%;
      }

      .title {
        font-size: 20px;
        line-height: 40px;
        color: $light-black;
      }
      .sub {
        font-size: 14px;
        line-height: 38px;
        color: $light-black;
      }
      form {
        display: flex;
        flex-direction: column;

        input,
        label {
          padding: 15px;
          border-radius: 4px;
          margin: 10px 0;
          font-size: 14px;
          background: $card-input-bg;
          border: none;
          outline: none;
          color: $light-black;
          &:focus {
            border: 1px solid $blue;
          }
        }
        label {
          &:hover {
            border: 1px solid $blue;
          }
        }
        input[type="file"] {
          display: none;
        }
        input[type="submit"] {
          background: $blue;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}

@import "../../assets/base.scss";

.dropdown {
  margin: 5px;
  position: relative;

  .title {
    color: $light-black;
    font-size: 14px;
    line-height: 21px;

    display: inline-flex;
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
    background: $dark-ash;
    border: 1px solid $blue;
    border-radius: 4px;

    i {
      margin: 0 6px;
      font-weight: bold;
    }
  }

  .wrap {
    position: absolute;
    left: 0;
    top: 100%;
    .items {
      display: inline-flex;
      flex-direction: column;
      background: white;
      margin: 5px 0;
      border-radius: 4px;
      min-width: 250px;
      z-index: 9999;

      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
      div {
        padding: 15px;
        cursor: pointer;
        color: $gray;
        &:hover {
          background: rgba($blue, 0.1);
        }
      }
    }
  }
}

@import "../../assets/base.scss";

.group {
  /* display: flex;*/ /* */ /* justify-content: center;*/ /**/ /* align-items: center;*/
  display: grid;
  margin: 70px 0;
  grid-template-columns: 50% 50%;
  @include mobile {
    grid-template-columns: 100%;
    margin: 10px auto;
    background: rgba($light-ash, 0.2);
  }

  .mid {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      flex-direction: column;
      margin: 40px auto;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile {
        flex-direction: column;
      }
    }

    .title {
      font-size: 32px;
      line-height: 100%;
      margin: 10px;
      color: $dark-gray;
    }

    .desc {
      font-size: 16px;
      line-height: 116%;
      margin: 10px;
      color: $gray;
      max-width: 290px;
    }

    a {
      margin: 10px;
      text-decoration: none;
      color: $darker-blue;
      font-size: 16px;
      line-height: 116%;
      display: flex;
      align-items: center;

      img {
        margin: 0 10px;
      }
    }
  }

  .side {
    width: 90%;
    position: relative;
    @include mobile {
      margin: 70px auto;
    }

    img {
      width: 100%;
      max-height: 372px;
    }

    .shape1 {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 20px;
      top: 30px;
      box-sizing: border-box;
      z-index: -1;
      border: 5px solid $darker-blue;
      background: $darker-blue;
      clip-path: polygon(0% 0%, 0 76%, 1% 77%, 1% 2%, 99% 3%, 99% 98%, 0 76%, 0 78%, 100% 100%, 100% 0%);
    }

    .shape2 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 20px;
      top: 30px;
      box-sizing: border-box;
      z-index: -1;
      border: 5px solid $darker-blue;
      background: $darker-blue;
      clip-path: polygon(0% 0%, 0% 99%, 1% 99%, 2% 3%, 99% 2%, 99% 77%, 0 98.5%, 0 100%, 100% 78%, 100% 0%);
    }
  }
}

@import "../../assets/base.scss";

.fullBanner {
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4b2400;
  margin-top: 50px;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    background: rgba(black, 0.3);
    width: 100%;
    height: 100%;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    .title {
      font-size: 32px;
      line-height: 120%;
      color: white;
      margin: 20px;
      text-align: center;
    }

    .bottom {
      margin: 20px;
    }
  }
}
